<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-primary">
          <button *ngIf="isRoleMaster" class="btn btn-warning float-right" routerLink="/systems/form">
            <i class="material-icons">add</i>
            Novo Sistema
          </button>
          <h4 class="card-title">Cadastrados</h4>
          <p class="card-category"></p>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table text-center">
              <thead class=" text-primary">
                <th>Nome do sistema</th>
                <th>Descrição do Sistema</th>
                <th *ngIf="isRoleAdminOrMaster">Ação</th>
              </thead>
              <tbody>
                <tr *ngFor="let system of systems">
                  <td>{{ system.nomeSistema }}</td>
                  <td>{{ system.descricaoSistema }}</td>
                  <td *ngIf="isRoleAdminOrMaster">
                      <button class="btn btn-default" routerLink="/systems/form/{{ system.id }}">
                        <i class="material-icons">edit</i>
                      </button>
                      <button class="btn btn-default" matTooltip="Exclui Sistema"
                      (click) = "confirmAction(system)">
                          <i class="material-icons">delete</i>
                      </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
