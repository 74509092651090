import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Jquery from 'jquery';
import { AuthService } from 'src/app/messaging/auth/auth.service';
import { LoaderService } from '../../../messaging/shared/loader/loader.service';
import { PasswordDialog } from '../password-change/password-change';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService
  ) {
  }

  ngOnInit(): void {
  }

  sidebarToggle(): Jquery {
    return Jquery('.navbar-toggler, body').toggleClass('toggled nav-open');
  }

  logout(): any {
    this.authService.clearSession();
    this.router.navigate(['/login']);
  }

  getTitle(): string { return this.titleService.getTitle(); }

  openDialog(): void {
    const dialogRef = this.dialog.open(PasswordDialog);
  }

}
