import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInterface } from './user.interface';
import { PasswordChangeInterface } from '../../layout/template/password-change/password-change.interface';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiURL: string = environment.apiURLBase + '/api/usuarios';

  constructor( private http: HttpClient ) { }

  save(user: UserInterface): Observable<UserInterface> {
    return this.http.post<UserInterface>(this.apiURL, user);
  }

  update(user: UserInterface): Observable<any>{
    return this.http.put<UserInterface>(`${this.apiURL}/${user.id}`, user);
  }

  delete(user: UserInterface): Observable<any>{
    return this.http.delete<any>(`${this.apiURL}/${user.id}`);
  }

  getUserById(id: number): Observable<UserInterface>{
    return this.http.get<UserInterface>(`${this.apiURL}/${id}`);
  }

  getUsers(): Observable<UserInterface[]>{
    return this.http.get<UserInterface[]>(this.apiURL);
  }

  updatePassword(password: PasswordChangeInterface): Observable<PasswordChangeInterface>{
    return this.http.post<PasswordChangeInterface>(`${this.apiURL}/mudarsenha`, password);
  }
  resetPasswordAdminMaster(): Observable<UserInterface> {
    return this.http.post<UserInterface>(this.apiURL + '/resetPassAdmin', null);
  }
}
