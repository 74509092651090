import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRole {

  permissions: string[] = [];

  constructor(
    private authService: AuthService,
  ) { }


  static getSessionRole(): string {
    return localStorage.getItem('ROLE');
  }

  async getSessionsPermissions(): Promise<any> {
    const permissions = [];
    const loggedUserPermissions = await this.authService.getLoggedUserPermissions().toPromise();

    loggedUserPermissions.forEach(permission => permissions.push(permission.nome));

    return permissions;

  }

}
