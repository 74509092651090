<div class="container-fluid">
  <div class="alert alert-danger" role="alert" *ngFor="let erro of errors">
    {{ erro }}
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-primary">
          <h4 class="card-title">Cadastro de usuário</h4>
          <p class="card-category"></p>
        </div>
        <div class="card-body">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">

            <mat-tab-group>
              <mat-tab label="Informações">
                <div class="container">
                  <div class="row">
                    <mat-form-field class="col-md-12 mt-3" formGroupName="sistemaExterno">
                      <mat-label class="bmd-label-floating">Sistemas</mat-label>
                      <mat-select #systemDropdown formControlName="id" class="form-control">
                        <mat-option *ngFor="let system of systems" [value]="system.id">
                          {{system.nomeSistema}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="row">
                    <mat-form-field class="col-md-4">
                      <mat-label class="bmd-label-floating">Nome</mat-label>
                      <input matInput formControlName="nomeUsuario" placeholder="Informe o nome" class="form-control">
                      <mat-hint>
                        <app-show-errors-form [ctrl]="userForm.get('nomeUsuario')"></app-show-errors-form>
                      </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-md-4">
                      <mat-label class="bmd-label-floating">Login</mat-label>
                      <input matInput formControlName="username" placeholder="Informe o login" class="form-control">
                      <mat-hint>
                        <app-show-errors-form [ctrl]="userForm.get('username')"></app-show-errors-form>
                      </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-md-4">
                      <mat-label class="bmd-label-floating">Senha</mat-label>
                      <input type="password" matInput formControlName="password" placeholder="Informe a senha"
                        class="form-control">
                      <mat-hint>
                        <app-show-errors-form [ctrl]="userForm.get('password')"></app-show-errors-form>
                      </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-md-4" formGroupName="userRole">
                      <mat-label class="bmd-label-floating">Papel</mat-label>
                      <mat-select formControlName="id" class="form-control"
                        [disabled]="disableDropdownAdmin(userForm.get('userRole').get('id').value)">
                        <mat-option *ngFor="let role of roles" [value]="role.id"
                          [disabled]="disableDropdownAdmin(role.id)">
                          {{role.descricao}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Serviços" *ngIf="checkforServiceRole(userForm.get('userRole').get('id').value)">
                <div class="container template-example-container">
                  <div class="row">
                    <div class="col-md-12 template-example-title">
                      <mat-label>Template de configuração do Sistema</mat-label>
                    </div>
                  </div>
                  <div class="row template-field-container">
                    <div class="col-md-2 template-field">
                      <mat-label>Host Autenticação:</mat-label>
                    </div>
                    <div class="col-md-10">
                      <mat-label>{{autenticacao}}</mat-label>
                      <button type="button" mat-icon-button matTooltip="Copiar Host Autenticação" [cdkCopyToClipboard]="autenticacao" aria-label="Copiar">
                        <i class="material-icons">content_copy</i>
                      </button>
                    </div>
                  </div>

                  <div class="row template-field-container">
                    <div class="col-md-2 template-field">
                      <mat-label>Host Base:</mat-label>
                    </div>
                    <div class="col-md-10">
                      <mat-label>{{apiBase}}</mat-label>
                      <button type="button" matTooltip="Copiar Host Base" mat-icon-button [cdkCopyToClipboard]="apiBase" aria-label="Copiar">
                        <i class="material-icons">content_copy</i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="container template-example-container">
                  <div class="row template-field-container">
                    <div class="col-md-2 template-field">
                      <mat-label>Observação: </mat-label>
                    </div>
                    <div class="col-md-10">
                     Apenas um único serviço de email deve estar ativo por usuário.
                    </div>
                  </div>
                </div>

                <mat-accordion class="container mt-2" formGroupName="configuracaoServico">
                  <mat-expansion-panel class="mt-2 mb-1" hideToggle *ngFor="let item of getConfiguracaoServico.controls"
                    [formGroup]="item">

                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        SERVIÇO#&nbsp;<strong>{{item.get('tipoDeServico').value}}</strong>
                      </mat-panel-title>
                      <mat-panel-description>
                        CONFIGURAÇÃO: {{item.get('configuracao').value}}
                      </mat-panel-description>
                        <i [matTooltip]="item.get('ativo').value === '1'? 'Serviço Ativo' : 'Serviço Inativo'"
                        class="material-icons" style="float:right">
                          {{item.get('ativo').value === '1'? 'mark_email_read' : 'unsubscribe'}}
                        </i>
                    </mat-expansion-panel-header>

                    <div class="container">
                      <div class="row">

                        <mat-form-field class="col-md-6">
                          <mat-label class="bmd-label-floating">Configuração</mat-label>
                          <input type="text" matInput formControlName="configuracao" class="form-control">
                        </mat-form-field>

                        <mat-form-field class="col-md-6">
                          <mat-label class="bmd-label-floating">Ativo</mat-label>
                          <mat-select formControlName="ativo" class="form-control" (selectionChange)="onStatusChange()">
                            <mat-option *ngFor="let a of ativo" [value]="a">
                              {{a === '1' ? 'Ativado' : 'Desativado'}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                      </div>
                    </div>

                    <div class="col-md-12">
                      <h6 *ngIf="protocolo != undefined" class="alert-success card-title" style="padding: 10px;">
                        PROTOCOLO - <strong>{{protocolo}}</strong></h6>
                      <button [disabled]="!userForm.valid && item.get('ativo').value !== '1'" (click)="sendTest(item.get('tipoDeServico').value)"
                        class="btn btn-info btn-sm"><i class="material-icons">send</i>&nbsp;Testar Envio</button>
                    </div>

                    <h6 class="alert-success card-title mt-3 mb-1" style="padding: 10px;">Lista de Parametros de Serviço
                    </h6>

                    <mat-vertical-stepper [linear]="false" #stepper>

                      <ng-template matStepperIcon="edit">
                          <mat-icon>{{item.get('ativo').value === '0' ? 'block' : 'create'}}</mat-icon>
                      </ng-template>

                      <ng-template matStepperIcon="done">
                        <mat-icon>{{item.get('ativo').value === '0' ? 'block' : 'create'}}</mat-icon>
                      </ng-template>

                      <mat-step *ngFor="let parametrosServico of item.get('listaParametrosDeServico').controls; let i = index"
                        [formGroup]="parametrosServico" [completed]="true">

                        <ng-template matStepLabel>
                          {{parametrosServico.get('parametro').get('nomeParametro').value}}

                          <span *ngIf="parametrosServico.get('valorParametro').validator | controlRequired">
                            <i [matTooltip]="parametrosServico.get('valorParametro').value !== ''? 'OK' : 'Valor obrigatório'"
                             class="material-icons" style="float:right">
                              {{parametrosServico.get('valorParametro').value !== ''? 'done' : 'cancel'}}
                            </i>
                          </span>

                        </ng-template>

                        <fieldset [disabled]="item.get('ativo').value === '0'">

                        <div class="row mt-2">
                          <div class="row">
                            <div class="col-md-12">

                              <mat-form-field class="col-md-6"
                              *ngIf="checkForStringType(parametrosServico.get('parametro').get('tipoDeDado').value)">

                                <mat-label class="bmd-label-floating">Valor Parametro</mat-label>

                                  <input matInput
                                  [attr.type]="checkforPasswordField(parametrosServico.get('parametro').get('nomeParametro').value)? 'password' : 'text'"
                                  matInput formControlName="valorParametro" class="form-control">

                              </mat-form-field>

                              <mat-form-field class="col-md-6"
                              *ngIf="checkForIntegerType(parametrosServico.get('parametro').get('tipoDeDado').value)">
                                <mat-label class="bmd-label-floating">Valor Parametro</mat-label>

                                  <input matInput min="0" max="65535"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                  type="number" formControlName="valorParametro" class="form-control">

                              </mat-form-field>

                              <mat-form-field floatLabel="always" appearance="none" class="col-md-3"
                               *ngIf="checkForBooleanType(parametrosServico.get('parametro').get('tipoDeDado').value)">

                                <mat-label style="color: rgba(0,0,0,.54)">Valor Parametro</mat-label>

                                <mat-slide-toggle formControlName="valorParametro"></mat-slide-toggle>

                                <textarea matInput hidden></textarea>

                              </mat-form-field>


                                <mat-icon matSuffix *ngIf="parametrosServico.get('parametro').get('helpParametro').value"
                                matTooltip="{{parametrosServico.get('parametro').get('helpParametro').value}}">
                                <em class="material-icons">info</em>
                                </mat-icon>

                              <div formGroupName="parametro">

                                <mat-form-field class="col-md-4">
                                  <mat-label>Tipo de Serviço</mat-label>
                                  <input type="text" matInput readonly formControlName="tipoDeServico"
                                    class="form-control">
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>Tipo de Dado</mat-label>
                                  <input type="text" matInput readonly formControlName="tipoDeDado"
                                    class="form-control">
                                </mat-form-field>

                                <mat-form-field class="col-md-4">
                                  <mat-label>Ativo</mat-label>
                                  <input type="text" matInput readonly formControlName="ativo" class="form-control">
                                </mat-form-field>

                              <div class="col-md-12" style="font-size: 0.7rem; color: rgba(0,0,0,.54)">
                                <mat-label *ngIf="checkForSpecialField(parametrosServico.get('parametro').get('nomeParametro').value)">
                                    OBS: Alguns provedores de email exigem que o parâmetro <strong>mail.smtp.user</strong>
                                    e o <strong>mail.smtp.from</strong> sejam iguais.
                                </mat-label>
                              </div>

                              </div>

                            </div>
                          </div>
                        </div>

                      </fieldset>
                      </mat-step>
                    </mat-vertical-stepper>

                  </mat-expansion-panel>
                </mat-accordion>

              </mat-tab>
            </mat-tab-group>

            <button mat-button routerLink="/users/list" class="btn btn-danger pull-right">
              <span class="material-icons vertical-align-sub">close</span> Cancelar
            </button>
              <button mat-button class="btn btn-success pull-right" [disabled]="!userForm.valid">
                <span class="material-icons vertical-align-sub">save</span> Salvar
              </button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
