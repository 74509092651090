import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingLayoutComponent } from 'src/app/layout/messaging-layout/messaging-layout.component';
import { AuthGuard } from '../auth/auth.guard';
import { UserFormComponent } from './user-form/user-form.component';
import { UserListComponent } from './user-list/user-list.component';
import { AuthAccessGuard } from '../auth/auth-access.guard';

const routes: Routes = [
  { path: 'users', component: MessagingLayoutComponent, canActivate: [AuthGuard], children: [
    { path: 'list', component: UserListComponent, canActivate: [AuthAccessGuard] },
    { path: 'form', component: UserFormComponent, canActivate: [AuthAccessGuard]  },
    { path: 'form/:id', component: UserFormComponent, canActivate: [AuthAccessGuard] },
    { path: '', redirectTo: '/users/list', pathMatch: 'full'}
  ], data: { role: [] }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
