import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors-form',
  templateUrl: './show-errors.component.html'
})
export class ShowErrorsFormComponent implements OnInit {
  @Input() ctrl: FormControl;

  private ERROR_MESSAGES = {
    required: () => 'Campo obrigatório',
    minlength: (par) => `Minimo de ${par.requiredLength} caracteres`
  };

  constructor() {}

  ngOnInit(): void {}

  /**
   * check form is valid
   * @returns true|false
   */
  shouldShowErrors(): boolean {
    return this.ctrl && this.ctrl.errors && this.ctrl.touched;
  }

  /**
   * case form is valid return errors messages
   * @returns array strings
   */
  listOfErrors(): string[] {
    return Object.keys(this.ctrl.errors).map(
      (key) => this.ERROR_MESSAGES[key](this.ctrl.getError(key))
    );
  }

}
