import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ProtocolsInterface } from '../protocol.interface';
import { ProtocolsService } from '../protocols.service';

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html'
})
export class ProtocolsComponent implements OnInit {
  
  public protocolForm: FormGroup;
  public protocols: ProtocolsInterface[];
  public errors: Array<string> = [];
  public protocoloListError = false;

  constructor(
    private protocolsService: ProtocolsService,
    private formBuilder: FormBuilder,
    private titleService: Title
  ) {
    this.titleService.setTitle('Protocolos');
  }

  ngOnInit(): void {
    this.protocolsService.getAllProtocols().subscribe({
      next: (res) => {
        this.protocols = res;
      }
    });
    this.protocolForm = this.formBuilder.group({
      protocolo: ['', Validators.required]
    })
  }

  searchProtocol() {
    
    this.protocolsService.getProtocol(this.protocolForm.get('protocolo').value).subscribe({
      next: (res) => {
        this.protocoloListError =  false;
        this.protocols = [res];
      },
      error: error =>{
        this.protocoloListError =  true;
        this.errors = ['Protocolo não localizado!'];
      }
    });
    
    return false;
    
  }

}
