import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthRole } from './auth-role';
import { log } from 'console';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })

export class AuthAccessGuard implements CanActivate {

    private userRole: string;

    constructor(
        private authService: AuthService,
        private router: Router
      ) { }
    

      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.getSessionUserRole().pipe(
          map(role => {
            this.userRole = role;
            if (this.userRole === 'ROLE_ADMIN' || this.userRole === 'ROLE_MASTER') {
              return true;
            } else {
              this.router.navigate(['not-authorized']);
              return false;
            }
          })
        );
      }

      isAdminOrMaster(): boolean {
        return this.userRole === 'ROLE_ADMIN' || this.userRole === 'ROLE_MASTER';
      }
    
      isMaster(): boolean {
        return this.userRole === 'ROLE_MASTER';
      }
    }
