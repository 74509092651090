// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const pathFolder = 'assets/config/configPortal.json';
const pathFolderExt = '../../../conf/configPortal.json';
const configPortal = loadVars(pathFolder, pathFolderExt);

export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  apiURLBase: configPortal.config.portal.apiURLBase,
  clientId: configPortal.config.portal.clientId,
  clientSecret: configPortal.config.portal.clientSecret,
  obterTokenUrl: '/oauth/token'
}
/*
export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  apiURLBase: 'http://localhost:8081/servico-mensageria',
  clientId: 'angular-app',
  clientSecret: '@321',
  obterTokenUrl: '/oauth/token'
};
*/
function loadVars(url: string, urlExt: string): any {
  return loadJSON(url, urlExt);
}
function loadJSON(filePath, filePathExt): any {
  const jsonExt = loadTextFileAjaxSync(filePathExt, 'application/json');
  if(jsonExt!=undefined){
    return JSON.parse(jsonExt);
  }else{
    const json = loadTextFileAjaxSync(filePath, 'application/json');
    return JSON.parse(json)
  }  
}
function loadTextFileAjaxSync(filePath, mimeType): any {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', filePath, false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status === 200) {
    return xmlhttp.responseText;
  } else {
    return null;
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
