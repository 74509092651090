import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemsRoutingModule } from './systems-routing.module';
import { SystemListComponent } from './system-list/system-list.component';
import { SystemFormComponent } from './system-form/system-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [SystemListComponent, SystemFormComponent],
  imports: [
    CommonModule,
    SystemsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxPermissionsModule.forRoot()
  ],
  exports: [NgxPermissionsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SystemsModule {
}
