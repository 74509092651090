import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";

@Pipe({
  name: "controlRequired"
})
export class ControlRequiredPipe implements PipeTransform {
  public transform(validatorFn: ValidatorFn): boolean {
    if (!validatorFn) {
      return false;
    }

    const validator = validatorFn({} as AbstractControl) || {};
    return validator && validator.required;
  }
}
