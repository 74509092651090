
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-warning card-header-icon">
          <div class="card-icon">
            <em class="material-icons">content_copy</em>
          </div>
          <p class="card-category">Filas Totais</p>
          <h3 class="card-title">{{filasTotais}}
          </h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-success card-header-icon">
          <div class="card-icon">
            <em class="material-icons">store</em>
          </div>
          <p class="card-category">Filas com Sucesso</p>
          <a *ngIf="filasSucesso>0" routerLink="/queue/list" [queryParams]="{tipo: 'SUCESSO'}" queryParamsHandling="merge">
            <h3 class="card-title">{{filasSucesso}}</h3>
          </a>
          <h3 *ngIf="filasSucesso==0" class="card-title">{{filasSucesso}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-success card-header-icon">
          <div class="card-icon">
            <em class="material-icons">store</em>
          </div>
          <p class="card-category">Filas em Processamento</p>
          <a *ngIf="filasEmProcessamento>0" routerLink="/queue/list" [queryParams]="{tipo: 'EMPROC'}" queryParamsHandling="merge">
           <h3 class="card-title">{{filasEmProcessamento}}</h3>
          </a>
          <h3 *ngIf="filasEmProcessamento==0" class="card-title">{{filasEmProcessamento}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-danger card-header-icon">
          <div class="card-icon">
            <em class="material-icons">info_outline</em>
          </div>
          <p class="card-category">Erros por Configuração</p>
          <a *ngIf="filasErroConfiguracao>0" routerLink="/queue/list" [queryParams]="{tipo: 'ERROCONF'}" queryParamsHandling="merge">
            <h3 class="card-title">{{filasErroConfiguracao}}</h3>
          </a>
          <h3 *ngIf="filasErroConfiguracao==0" class="card-title">{{filasErroConfiguracao}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-danger card-header-icon">
          <div class="card-icon">
            <em class="material-icons">info_outline</em>
          </div>
          <p class="card-category">Erros no Envio</p>
          <a *ngIf="filasErroEnvio>0" routerLink="/queue/list"
            [queryParams]="{tipo: 'ERROENVIO'}" queryParamsHandling="merge">
            <h3 class="card-title">{{filasErroEnvio}}</h3>
          </a>
          <h3 *ngIf="filasErroEnvio==0" class="card-title">{{filasErroEnvio}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-header card-header-danger card-header-icon">
          <div class="card-icon">
            <em class="material-icons">info_outline</em>
          </div>

          <p class="card-category">Erros no Processamento</p>
          <a *ngIf="filasErrorProcessamento>0" routerLink="/queue/list"
            [queryParams]="{tipo: 'ERROPROC'}" queryParamsHandling="merge">
            <h3 class="card-title">{{filasErrorProcessamento}}</h3>
          </a>
          <h3 *ngIf="filasErrorProcessamento==0" class="card-title">{{filasErrorProcessamento}}</h3>
        </div>
        <div class="card-footer">
          <div class="stats">
            <em class="material-icons">list</em>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

