<div class="container-fluid">
  <form [formGroup]="userListForm">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary">
            <button class="btn btn-warning float-right ml-1" routerLink="/users/form">
              <i class="material-icons">add</i>
              Novo
            </button>          
            <h4 class="card-title ">Cadastrados</h4>
            <p class="card-category"></p>
          </div>
          <div class="card-body">
            <div class="table-responsive text-center">
              <table *ngIf="showUsers" class="table">
                <thead class="text-primary">
                  <th>Nome do sistema</th>
                  <th>Usuário</th>
                  <th>Login</th>
                  <th>Tipo</th>
                  <th>Ação</th>
                </thead>
                <tbody>
                  <tr *ngFor="let user of users | usersFilter:filter;">
                    <td>{{ user.sistemaExterno.nomeSistema }}</td>
                    <td>{{ user.nomeUsuario }}</td>
                    <td>{{ user.username }}</td>
                    <td>{{ user.userRole.descricao }}</td>
                    <td>
                      <button mat-button class="btn btn-default" routerLink="/users/form/{{ user.id }}">
                        <em class="material-icons">edit</em>
                      </button>
                      <button mat-button class="btn btn-default ml-1" (click) = "confirmAction(user)"
                      matTooltip="Exclui Usuário">
                          <em class="material-icons">delete</em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" *ngIf="!showUsers">
              <mat-card (click)="showUsersSystem(system)" class="text-center example-card col-md-2 ml-2 mb-3 pointer card-system" *ngFor="let system of systemsUsers | keyvalue">
                <span>{{system.value.nomeSistema}}</span>
                <mat-card-content class="text-center mt-2 mb-2">
                  <img class="text-center" width="100px" src="assets/img/server_icon.png" alt="Servidor">
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
