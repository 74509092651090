import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { throwError } from "rxjs";
import { email } from "./email/email";
import { SendEmailTestInterface } from "./email/send-email-test.interface";
import { SendEmailService } from "./email/send-email.service";

@Injectable({
  providedIn: 'root'
})
export class SendTest {

  public protocolo: string;

  constructor(
    private sendEmailService: SendEmailService
  ) {
  }

  sendTest(tipoDeServico: string, form: FormGroup) {
    if (tipoDeServico === "EMAIL") {
      form.value.configuracaoServico[0].listaParametrosDeServico.forEach(parametroServico => {
        if(parametroServico.parametro.nomeParametro === "mail.smtp.from") {
          if (parametroServico.valorParametro) {
            email['processamentoEmail'].destinatarios = [];
            email['processamentoEmail'].destinatarios.push(parametroServico.valorParametro);
          } else {
            throw throwError("Email: Destinatário vazio");
          }
          email['usuario'] = form.value.username;
          email['usuarioSistema'] = form.value;
        }
      });
      return this.sendEmailService.sendEmailTest(email);
    } else {
      throw throwError("Email: Serviço não suportado");
    }
  }

}
