import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "../../../messaging/auth/auth.service";
import { UserService } from "../../../messaging/users/user.service";
import { MustMatch } from "../../../messaging/shared/validators/must-match.validator";

@Component({
  selector: 'password-change',
  templateUrl: './password-change.html',
})
export class PasswordDialog implements OnInit {

  public fieldTextType: boolean;

  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: MustMatch('newPassword', 'confirmPassword') });
  }

  closeDialog(){
    this.dialogRef.close(true);
    return false;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    return false;
  }

  onSubmit() {
    console.log(this.form);

    this.userService.updatePassword(this.form.value).subscribe({
      next: (res) => {
        this.authService.clearSession();
        this.dialogRef.close(true);
        this.router.navigate(['/login']);
      }
    })

    return false;
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('newPassword').value;

    return password === confirmPassword ? null : { notSame: true }
  }
}
