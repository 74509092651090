<h2 class="text-uppercase text-center" mat-dialog-title>Alteração de senha</h2>
<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Senha atual:</label>
            <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="**********">
          </div>
          <div class="form-group">
            <label>Nova Senha:</label>
            <input [type]="fieldTextType ? 'text' : 'password'" formControlName="newPassword" class="form-control" placeholder="**********">
          </div>
          <div class="form-group">
            <label>Confirme a senha:</label>
            <input [type]="fieldTextType ? 'text' : 'password'" formControlName="confirmPassword" class="form-control" placeholder="**********">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button mat-button class="btn" (click)="toggleFieldTextType()"><i class="material-icons">
            {{fieldTextType ? 'visibility' : 'visibility_off'}}
          </i></button>
          <button mat-button class="btn btn-success" [disabled]="!form.valid" mat-button>
            <i class="material-icons">done</i> Salvar</button>
            <button mat-button class="btn btn-danger" mat-button (click)="closeDialog()">
              <i class="material-icons">close</i> Cancelar</button>
        </div>
      </div>
    </div>

  </form>
</div>
