<div class="container-fluid">
  <div class="alert alert-danger" role="alert" *ngFor="let erro of errors">
    {{ erro  }}    
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-primary">
          <h4 class="card-title">Cadastro de sistema</h4>
          <p class="card-category"></p>
        </div>
        <div class="card-body">
          <form [formGroup]="systemForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field>
                    <mat-label class="bmd-label-floating">Nome</mat-label>
                    <input matInput formControlName="nomeSistema" placeholder="Nome do Sistema" class="form-control">
                    <mat-hint><app-show-errors-form [ctrl]="systemForm.get('nomeSistema')"></app-show-errors-form></mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field>
                    <mat-label class="bmd-label-floating">Descrição</mat-label>
                    <textarea rows="5" matInput formControlName="descricaoSistema" placeholder="Descrição do Sistema" class="form-control"></textarea>
                    <mat-hint><app-show-errors-form [ctrl]="systemForm.get('descricaoSistema')"></app-show-errors-form></mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field formGroupName="usuarioAdministracao">
                    <mat-label class="bmd-label-floating">Login</mat-label>
                    <input matInput formControlName="username" placeholder="Login" class="form-control">
                    <mat-hint><app-show-errors-form [ctrl]="systemForm.get('username')"></app-show-errors-form></mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field formGroupName="usuarioAdministracao">
                    <mat-label class="bmd-label-floating">Senha</mat-label>
                    <input type="password" matInput formControlName="password" placeholder="Senha" class="form-control">
                    <mat-hint><app-show-errors-form [ctrl]="systemForm.get('password')"></app-show-errors-form></mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <button class="btn btn-danger pull-right" routerLink="/systems/list">
              <span class="material-icons vertical-align-sub">close</span>
              Cancelar
            </button>
            <button class="btn btn-success pull-right" [disabled]="!systemForm.valid">
              <span class="material-icons vertical-align-sub">save</span>
              Salvar
            </button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
