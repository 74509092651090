import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-system-form',
  templateUrl: './system-form.component.html',
  styleUrls: ['./system-form.component.css']
})
export class SystemFormComponent implements OnInit {
  public systemForm: FormGroup;
  private id: number;
  public errors: Array<string> = [];

  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    private systemService: SystemService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.titleService.setTitle('Formulário Cadastro de Sistemas');
  }

  ngOnInit(): void {
    this.systemForm = this.formBuilder.group({
      id: [this.id],
      nomeSistema: ['', [Validators.required, Validators.minLength(3)]],
      descricaoSistema: ['', [Validators.required]],
      usuarioAdministracao: this.formBuilder.group({
        username: ['', [Validators.required, Validators.minLength(3)]],
        password: ['', [Validators.required, Validators.minLength(3)]]
      })
    });

    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.id = param.id;
        this.systemService.getSystemById(this.id).subscribe(res => this.systemForm.patchValue({
          id: res.id,
          nomeSistema: res.nomeSistema,
          descricaoSistema: res.descricaoSistema,
          ativo: res.ativo,
          usuarioAdministracao: res.usuarioAdministracao
        }));
      }
    });
  }

  get getControl(): any {
    return this.systemForm.controls;
  }

  onSubmit(): void {
    if (!this.systemForm.invalid) {
      return (this.id ? this.updateSystem() : this.createSystem());
    }
  }

  private createSystem(): void {
    this.systemService.save(this.systemForm.value).subscribe({
      next: () => {
        this.router.navigate(['/systems/list']);
      },
      error: error => {
        this.errors = [error.error.errors];
      }
    });
  }

  private updateSystem(): void {
    this.systemService.update(this.systemForm.value).subscribe({
      next: () => {
        this.router.navigate(['/systems/list']);
      },
      error: error => {
        this.errors = [error.error.errors];
      }
    });
  }

}
