import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { QueueService } from '../queue.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { DashboardInterface } from '../../dashboard/dashboard.interface';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-protocol-list',
  styleUrls: ['queue-list.component.css'],
  templateUrl: './queue-list.component.html'
})


export class QueueListComponent implements OnInit, AfterViewInit {

  public tipo: string;
  public pageNumber: number = 0;
  public pageSize: number = 10;
  public totalLength: number = 500;
  public queueForm: FormGroup;
  public errors: Array<string> = [];
  public queueListError = false;
  public dataSource = new MatTableDataSource<DashboardInterface>();
  public selection = new SelectionModel<DashboardInterface>(true, []);
  public displayedColumns: string[] = ['select', 'protocolo', 'dataInicioProcessamento', 
    'statusProcessamento', 'mensagemErro'];

  constructor(
    private queueService: QueueService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.tipo =  params['tipo'];
    });
    
    this.carregandoProcessamentos();
    
    this.queueForm = this.formBuilder.group({
      queueInput: [''],
      protocolInput: [''],
      dateInput:[''],
      statusInput:[''],
      mensagemInput:['']
    });
  }

  aplicarFiltroProtocolo() {

    const protocolValue = this.queueForm.get('queueInput').value?? '';

    if(protocolValue === "") {
      this.carregandoProcessamentos();
    } else {
        this.dashboardService.getForStatusProcessamentoByProtocol(protocolValue, this.pageNumber,
                                                                                 this.pageSize).subscribe({

          next: (res) => {
            this.selection = new SelectionModel<DashboardInterface>(true, []);
            this.dataSource.data = res.pageContent;
            this.pageNumber = res.currentPage;
            this.totalLength = res.totalItems;
          },
          error: error => {
            this.queueListError =  true;
            this.errors = ['Nenhum protocolo foi encontrado'];
          }

      });
   }

  }

  aplicarFiltroTabela() {

    const protocolValue = this.queueForm.get('protocolInput').value ?? '';
    const dateValue = this.queueForm.get('dateInput').value ?? '';
    const statusValue = this.queueForm.get('statusInput').value ?? '';
    const messageValue = this.queueForm.get('mensagemInput').value ?? '';

    const filters = protocolValue + '$' + dateValue + '$' + statusValue + '$' + messageValue;
    this.dataSource.filter = filters.trim().toLowerCase();

  }


  getFilterPredicate() {
   return (data: DashboardInterface, filters:string) => {

    const filterArray = filters.split('$');
    const protocolValue = filterArray[0];
    const dateValue = filterArray[1];
    const statusValue = filterArray[2];
    const messageValue = filterArray[3];

    const matchFilter = [];

    matchFilter.push(data.protocolo.toLowerCase().includes(protocolValue));
    matchFilter.push(data.dataInicioProcessamento.toLowerCase().includes(dateValue));
    matchFilter.push(data.statusProcessamento.toLowerCase().includes(statusValue));
    matchFilter.push(data.mensagemErro.toLowerCase().includes(messageValue));

    return matchFilter.every(Boolean);

  };

}

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel="Itens por página:";
    this.paginator._intl.firstPageLabel="Primeira Página";
    this.paginator._intl.lastPageLabel="Última Página";
    this.paginator._intl.previousPageLabel="Página Anterior";
    this.paginator._intl.nextPageLabel="Próxima Página";
    this.dataSource.filterPredicate = this.getFilterPredicate(); 
    this.dataSource.sort = this.sort;
  }

  carregandoProcessamentos(){

    this.dashboardService.getAllForStatusProcessamentoPageable(this.tipo, 
                                                              this.pageNumber,
                                                              this.pageSize).subscribe( {
      next: (res) => {
        this.selection = new SelectionModel<DashboardInterface>(true, []);
        this.dataSource.data = res.pageContent;
        this.pageNumber = res.currentPage;
        this.totalLength = res.totalItems;
      },
      error: error =>{
        this.queueListError =  true;
        this.errors = ['Falha em buscar as filas'];
      }
    });
    
  }

  onChangePage(event: any) {
      this.pageSize = event.pageSize;
      this.pageNumber = event.pageIndex;
      this.carregandoProcessamentos();
  }

  reeenviaMensagem(){
    //validando quantos itens foram selecionados.
    let lista: DashboardInterface[] = [];    
    for (let item of this.selection.selected) {
      lista.push(item);
    }
    
    this.queueService.reenviarMensagem(lista).subscribe({
      next: (res) => {
        this.carregandoProcessamentos();
      },
      error: error =>{
        this.queueListError =  true;
        this.errors = ['Falha ao processar o reenvio!'];
      }
    });
   
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DashboardInterface): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.protocolo + 1}`;
  }
}