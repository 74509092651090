import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtocolsInterface } from './protocol.interface';


@Injectable({
  providedIn: 'root'
})
export class ProtocolsService {
  apiURL: string = environment.apiURLBase + '/api/batch/protocolos';

  constructor( private http: HttpClient ) { }

  getAllProtocols(): Observable<ProtocolsInterface[]>{
    return this.http.get<ProtocolsInterface[]>(this.apiURL);
  }

  getProtocol(protocol: string): Observable<ProtocolsInterface>{
    return this.http.get<ProtocolsInterface>(`${this.apiURL}/porId/${protocol}`);
  }
}
