import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthRole } from 'src/app/messaging/auth/auth-role';

@Component({
  selector: 'app-messaging-layout',
  templateUrl: './messaging-layout.component.html',
  styleUrls: ['./messaging-layout.component.css']
})
export class MessagingLayoutComponent implements OnInit {

  permissions: string[] = [];

  constructor(
    private permissionsService: NgxPermissionsService,
    private authRole: AuthRole
  ) { }


  ngOnInit(): void {
    this.authRole.getSessionsPermissions().then(permissions => {
      this.permissions = permissions;
      this.permissionsService.loadPermissions(this.permissions);
    });
  }
}
