import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SystemInterface } from '../system.interface';
import { SystemService } from '../system.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthAccessGuard } from '../../auth/auth-access.guard';

@Component({
  selector: 'app-system-list',
  templateUrl: './system-list.component.html',
  styleUrls: ['./system-list.component.css']
})
export class SystemListComponent implements OnInit {

  public systems: SystemInterface[];
  public errors: Array<string> = [];
  public errorUser = false;

  isRoleMaster: boolean = false;
  isRoleAdminOrMaster: boolean = false;

  constructor(
    private titleService: Title,
    private service: SystemService,
    private router: Router,
    private dialog : MatDialog,
    private authService : AuthAccessGuard

  ) {
    this.titleService.setTitle('Sistemas');
  }

  ngOnInit(): void {
    this.service.getSystems().subscribe(res => {
      this.systems = res;
    });

    this.isRoleMaster = this.authService.isMaster();
    this.isRoleAdminOrMaster = this.authService.isAdminOrMaster(); 

  }

  confirmAction(systemSelected: SystemInterface) {

    const dialogData = new ConfirmDialogModel("Remover Sistema", "Deseja remover o sistema selecionado?");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.preparaDelecao(systemSelected)
        }
    })

  }

  preparaDelecao(system: SystemInterface){
    this.service.remove(system).subscribe({
      next: () => {        
        this.errorUser = false;        
        this.reloadCurrentRoute();
      },
      error: error => {
        console.log('erro');
        this.errorUser = true;
        this.errors = [error.error.errors];
      }
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }
}
