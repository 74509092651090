import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProtocolsRoutingModule } from './protocols-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProtocolsComponent } from './protocol-list/protocol-list.component';

@NgModule({
  declarations: [ProtocolsComponent],
  imports: [
    CommonModule,
    ProtocolsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProtocolsModule { }
