import { Component, OnInit } from '@angular/core';
import { AuthRole } from 'src/app/messaging/auth/auth-role';
import { SidebarInterface } from './sidebar.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  items: SidebarInterface[];
  constructor() {}

  ngOnInit(): void {
    if (AuthRole.getSessionRole() !== 'ROLE_MASTER') {
      this.items = this.getDashboardItems();
    } else {
      this.items = this.getDashboardItems().filter(el => el.title !== 'Dashboard')
    }
  }

  getDashboardItems() : SidebarInterface[] {
     const sidebarValues : SidebarInterface[] = [
     { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '', role: 'ACESSO_DASHBOARD' },
     { path: '/systems/list', title: 'Sistemas',  icon:'dns', class: '', role: 'ACESSO_SISTEMAS' },
     { path: '/protocols/list', title: 'Protocolos',  icon:'rule', class: '', role: 'ACESSO_SISTEMAS' },
     { path: '/users/list', title: 'Usuarios',  icon:'content_paste', class: '', role: 'ACESSO_USUARIOS' }
     ]
     return sidebarValues;
  }

}



