import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DashboardInterface } from './dashboard.interface';
import { DashboardPageableInterface } from './dashboard-pageable.interface';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiURL: string = environment.apiURLBase + '/api/';

  constructor( private http: HttpClient ) { }

  getAllFilas(): Observable<DashboardInterface[]>{
    return this.http.get<DashboardInterface[]>(this.apiURL + 'batch/filas');
  }

  getAllForStatusProcessamento(status: string): Observable<DashboardInterface[]>{
    return this.http.get<DashboardInterface[]>(this.apiURL + 'batch/filas/status/' + status);
  }

  getForStatusProcessamentoByProtocol(protocol: string, pageNumber: number, pageSize: number): Observable<DashboardPageableInterface> {

    let pageOptions = {
      page: pageNumber.toString(),
      size: pageSize.toString()
    }

    return this.http.get<DashboardPageableInterface>(this.apiURL + 'batch/filas/find/'+ protocol, {params: pageOptions});
  }

  getAllForStatusProcessamentoPageable(status: string, pageNumber: number, pageSize: number): Observable<DashboardPageableInterface>{

    let pageOptions = {
      page: pageNumber.toString(),
      size: pageSize.toString()
    }

    return this.http.get<DashboardPageableInterface>(this.apiURL + 'batch/filas/status/' + status, {params: pageOptions});
  }

  getCountForStatusProcessamento(status: string): Observable<number>{
    return this.http.get<number>(this.apiURL + 'batch/filas/status/total/' + status);
  }

  getCountAllBySistema(): Observable<number>{
    return this.http.get<number>(this.apiURL + 'batch/filas/total/');
  }

}
