import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tokenString = localStorage.getItem('access_token');
    let id_sistema = localStorage.getItem('id_sistema');
    const url = request.url;

    if (tokenString && !url.endsWith('/oauth/token')){
      const token = JSON.parse(tokenString || '');
      const jwt = token.access_token;
      if(id_sistema == null){
        id_sistema = '0';
      }
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + jwt,
          'id_sistema': id_sistema
        })
      });
    }

    return next.handle(request);
  }
}
