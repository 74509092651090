import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public filasTotais: number;
  public filasSucesso: number;
  public filasErroConfiguracao: number;
  public filasErroEnvio: number;
  public filasErrorProcessamento: number;
  public filasEmProcessamento: number;

  constructor(
    private titleService: Title,
    private dashboardService: DashboardService
  ) {
    this.titleService.setTitle('Dashboard');
  }

  ngOnInit(): void {
    this.dashboardService.getCountAllBySistema().subscribe(res => this.filasTotais = res);
    this.dashboardService.getCountForStatusProcessamento('SUCESSO').subscribe(res => this.filasSucesso = res);
    this.dashboardService.getCountForStatusProcessamento('ERROCONF').subscribe(res => this.filasErroConfiguracao = res);
    this.dashboardService.getCountForStatusProcessamento('ERROENVIO').subscribe(res => this.filasErroEnvio = res);
    this.dashboardService.getCountForStatusProcessamento('ERROPROC').subscribe(res => this.filasErrorProcessamento = res);
    this.dashboardService.getCountForStatusProcessamento('EMPROC').subscribe(res => this.filasEmProcessamento = res);

  }

}