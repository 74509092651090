<div class="container">
  <div class="row justify-content-center">
      <div class="col-lg-5">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
              <div class="card-header">
                  <h3 class="text-center font-weight-light my-4">PORTAL MENSAGERIA</h3>
              </div>
              <div class="card-body">
                  <div class="alert alert-danger" *ngIf="loginError">
                    {{ errors }}
                  </div>
                  <div class="alert alert-success" *ngIf="loginSuccess">
                    {{ errors }}
                  </div>
                  <form [formGroup]="formulario" (ngSubmit)="onSubmit();">
                      <div class="form-group">
                          <label for="username" class="small mb-1">Login:</label>
                          <input type="text" name="username" id="username" formControlName="username"
                              class="form-control py-4"
                              placeholder="Informe o login"/>
                        <mat-hint>
                            <app-show-errors-form [ctrl]="formulario.get('username')"></app-show-errors-form>
                        </mat-hint>
                      </div>
                      <div class="form-group">
                          <label for="password" class="small mb-1">Password:</label>
                          <input type="password" name="password" id="password" formControlName="password"
                              class="form-control py-4"
                              placeholder="Informe o password"/>
                            <mat-hint>
                                <app-show-errors-form [ctrl]="formulario.get('password')"></app-show-errors-form>
                            </mat-hint>
                      </div>
                      <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
                          <button type="submit" class="btn btn-primary">
                            <em class="material-icons">login</em>&nbsp;
                              Entrar
                          </button>
                          <button type="button" (click)="onResetSenha()" class="btn btn-info m-lg-2" 
                          *ngIf="formulario.get('username').value=='admin_master'">
                            <em class="material-icons">autorenew</em>&nbsp;
                            Reset senha
                        </button>   
                      </div> 
                      <div class="form-group text-center">
                        <label class="mb-2">{{currentApplicationVersion}}</label>
                      </div>                                          
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
