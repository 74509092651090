<div class="container-fluid">
  <div class="alert alert-danger" *ngIf="protocoloListError">
    {{ errors }}
  </div>
  <div class="card-body">
    <form [formGroup]="protocolForm">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-primary">
              <button (click)="searchProtocol()" class="btn btn-warning float-right">
                <i class="material-icons">search</i>
                Buscar
              </button>
              <input matInput formControlName="protocolo" 
              placeholder="Informe o número" class="form-control float-right"
              style="width: 40%; margin-right: 5px;">
              <h4 class="card-title">Protocolos</h4>
              <p class="card-category"></p>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead class=" text-primary">
                    <th>Protocolo</th>
                    <th>Status do processamento</th>
                    <th>Tipo de serviço</th>
                    <th>Data de cadastro</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let protocol of protocols">
                      <td>{{ protocol.protocolo }}</td>
                      <td>{{ protocol.statusProcessamento }}</td>
                      <td>{{ protocol.dataAgendamento == null ? 'Envio Imediato' : dataAgendamento }}</td>
                      <td>{{ protocol.dataCadastro | date: 'dd/MM/yyyy hh:mm:ss a' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
