import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingLayoutComponent } from 'src/app/layout/messaging-layout/messaging-layout.component';
import { AuthGuard } from '../auth/auth.guard';
import { ProtocolsComponent } from './protocol-list/protocol-list.component';
import { AuthAccessGuard } from '../auth/auth-access.guard';

const routes: Routes = [
  { path: 'protocols', component: MessagingLayoutComponent, canActivate: [AuthGuard], children: [
    { path: 'list', component: ProtocolsComponent, canActivate: [AuthAccessGuard]},
    { path: '', redirectTo: '/protocols/list', pathMatch: 'full'}
  ], data: { role: [] }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtocolsRoutingModule { }
