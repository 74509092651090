import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SystemInterface } from './system.interface';


@Injectable({
  providedIn: 'root'
})
export class SystemService {
  apiURL: string = environment.apiURLBase + '/api/sistemas';

  constructor( private http: HttpClient ) { }

  save(system: SystemInterface): Observable<SystemInterface> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<SystemInterface>(this.apiURL, system, {headers: headers});
  }

  update(system: SystemInterface): Observable<any>{
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.put<SystemInterface>(`${this.apiURL}/${system.id}`, system, options);
  }

  remove(system: SystemInterface): Observable<any>{
    return this.http.delete<any>(`${this.apiURL}/${system.id}`);
  }

  getSystemById(id: number): Observable<SystemInterface>{
    return this.http.get<SystemInterface>(`${this.apiURL}/${id}`);
  }

  getSystems(): Observable<SystemInterface[]>{
    return this.http.get<SystemInterface[]>(this.apiURL);
  }
}
