import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemInterface } from '../../systems/system.interface';
import { SystemService } from '../../systems/system.service';
import { ParametrosService } from '../configuracao-servico/parametros.service';
import { SendTest } from '../configuracao-servico/test-send/send-test';
import { UserRoleTypeEnum } from '../user-role.enum';
import { UserRoleInterface } from '../user-role.interface';
import { UserRoleService } from '../user-roles.service';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AuthRole } from '../../auth/auth-role';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {
      displayDefaultIndicatorType: false
    }
  }]
})
export class UserFormComponent implements OnInit, AfterViewInit {
  public autenticacao: string = environment.apiURLBase + '/oauth/token';
  public apiBase: string = environment.apiURLBase + '/api/';
  public userForm: FormGroup;
  public id: number;

  public systems: SystemInterface[];
  public roles: UserRoleInterface[];

  public tipoDeServico: [];
  public tipoDeDados = [];

  public ativo = ["0", "1"];
  public obrigatorio = ["0", "1"];

  public sendTestResult: boolean;

  public protocolo: string;

  public errors: Array<string> = [];
  public errorUser = false;

  @ViewChild('systemDropdown') systemDropdown: MatSelect;

  constructor(
    private titleService: Title,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private systemService: SystemService,
    private userRoleService: UserRoleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private parametrosServices: ParametrosService,
    private sendTestParameter: SendTest
  ) {
    this.titleService.setTitle('CADASTRO DE USUÁRIOS');
  }

  ngOnInit(): void {
    this.systemService.getSystems().subscribe(res => this.systems = res);
    this.userRoleService.getUserRoles().subscribe(res => this.roles = res);
    this.parametrosServices.getAllTipoDeDados().subscribe(res => this.tipoDeDados = res);
    this.parametrosServices.getAllTipoDeServico().subscribe(res => this.tipoDeServico = res);

    this.userForm = this.formBuilder.group({
      id: [this.id],
      nomeUsuario: ['', [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      sistemaExterno: this.formBuilder.group({
        id: ['', [Validators.required]]
      }),
      userRole: this.formBuilder.group({
        id: ['', [Validators.required]]
      }),
      configuracaoServico: this.formBuilder.array([])
    });

    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.id = param.id;
        this.userService.getUserById(this.id).subscribe({
          next: (res) => {

            for (let configServico in res.configuracaoServico) {

              res.configuracaoServico[configServico].listaParametrosDeServico.sort((a,b) => {
                return a.parametro.nomeParametro.localeCompare(b.parametro.nomeParametro)
            });

                this.addConfiguracaoServico(res.configuracaoServico[configServico], configServico);
            }

            this.convertValue(res, true)
            this.userForm.patchValue(res);

          }
        });

      }
    });

  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (AuthRole.getSessionRole() !== 'ROLE_MASTER' && this.systemDropdown.options.first !== undefined) {
        this.systemDropdown.options.first.select();
        this.systemDropdown.setDisabledState(true);
      }
    }, 500);

  }

  get getControl(): any {
    return this.userForm.controls;
  }

  onSubmit(): void {
    if (!this.userForm.invalid) {
      return (this.id ? this.updateUser() : this.createUser());
    }
  }

  disableDropdownAdmin(id: number): boolean {
    return id === UserRoleTypeEnum.ROLE_MASTER || id === UserRoleTypeEnum.ROLE_ADMIN;
  }

  checkforServiceRole(id: number): boolean {
    return id === UserRoleTypeEnum.ROLE_SERVICO;
  }

  checkforPasswordField(parameterName: string): boolean {
      return parameterName.indexOf("password") !== -1;
  }

  checkForSpecialField(parameterName: string): boolean {
    return parameterName === "mail.smtp.from" || parameterName === "mail.smtp.user";
  }

  checkForIntegerType(parameterType: string): boolean {
    return parameterType === "INTEGER";
  }

  checkForBooleanType( parameterType: string): boolean {
    return parameterType === "BOOLEAN";
  }

  checkForStringType(parameterType: string): boolean {
    return parameterType === "STRING";
  }

  private createUser(): void {
    this.userService.save(this.convertValue(this.userForm.value, false)).subscribe({
      next: () => {
        this.errorUser = false;
        this.router.navigate(['/users/list']);
      },
      error: (error) => {
        this.errorUser = true;
        this.errors = [error.error.errors];
      }
    });
  }

  convertValue(res, from: boolean)  {

    for (let configServico in res.configuracaoServico) {
      for (let obj of res.configuracaoServico[configServico].listaParametrosDeServico) {
          if (obj.parametro.tipoDeDado === 'BOOLEAN') {
             if (from) {
              obj.valorParametro = "true" === obj.valorParametro;
             } else {
              obj.valorParametro = obj.valorParametro? "true" : "false";
             }
          }
      }
    }

    return res;

  }

  private updateUser(): void {
    this.userService.update(this.convertValue(this.userForm.value, false)).subscribe({
      next: () => {
        this.errorUser = false;
        this.router.navigate(['/users/list']);
      },
      error: error => {
        this.errorUser = true;
        this.errors = [error.error.errors];
      }
    });
  }

  /**
   * CONFIGURACOES SERVICO
   */

  newConfiguracaoServico(): FormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      ativo: ['', [Validators.required]],
      configuracao: ['', [Validators.required]],
      tipoDeServico: ['', [Validators.required]],
      listaParametrosDeServico: this.formBuilder.array([])
    });
  }

  onStatusChange() {


    for (let configServico of this.userForm.get('configuracaoServico')['controls']) {

      let ativoValue = configServico.value.ativo;

      for (let listaParametros of configServico.get('listaParametrosDeServico')['controls']) {

        let valorParametroCtrl = listaParametros.get('valorParametro');
        let obrigatorioValue = listaParametros.get('parametro').get('obrigatorio').value;

        if (ativoValue === "1" && obrigatorioValue === "1") {
          valorParametroCtrl.setValidators([Validators.required]);
        } else {
          valorParametroCtrl.clearValidators();
        }

        valorParametroCtrl.updateValueAndValidity({onlySelf: false});

      }
    }

  }

  addConfiguracaoServico(configuracaoServico, indexConfigServico) {
    this.configuracaoServico().push(this.newConfiguracaoServico());

    const listaParametrosServico = this.configuracaoServico().at(indexConfigServico);

    configuracaoServico.listaParametrosDeServico.forEach((value, _key) => {
      this.addListaParametrosDeServico(listaParametrosServico, configuracaoServico.ativo, value);
    });
  }

  configuracaoServico(): FormArray {
    return this.userForm.get('configuracaoServico') as FormArray;
  }

  get getConfiguracaoServico(): FormArray {
    return this.userForm.get('configuracaoServico') as FormArray;
  }

  /**
   * CONFIGURACOES SERVICO -> LISTAGEM PARAMETROS SERVICOS
   */

  newListaParametrosDeServico(fieldRequired: boolean): FormGroup {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      valorParametro: ['', fieldRequired ? Validators.required : null],
      parametro: this.formBuilder.group({
        id: ['', [Validators.required]],
        nomeParametro: ['', [Validators.required]],
        helpParametro: [''],
        tipoDeServico: ['', [Validators.required]],
        tipoDeDado: ['', [Validators.required]],
        ativo: ['', [Validators.required]],
        obrigatorio: ['']
      })
    });
  }

  addListaParametrosDeServico(listaParametroServico, ativo, obj) {
    let requiredField = ativo === "1" && obj.parametro.obrigatorio === "1";
    listaParametroServico.get('listaParametrosDeServico').push(this.newListaParametrosDeServico(requiredField));
  }


  /**
   * TEST SERVICES
   */
  sendTest(tipoDeServico: string) {
    this.sendTestParameter.sendTest(tipoDeServico, this.userForm).subscribe({
      next: (res) => {
        this.protocolo = res['protocolo'];
      },
      error: error => {
        this.errorUser = true;
        this.errors = [error.error.errors];
      }
    });

    return false;
  }

}
