import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorsFormComponent } from './show-errors-form/show-errors-form.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

const components = [
  ShowErrorsFormComponent,
  ConfirmDialogComponent
];

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [components]
})
export class SharedModule { }
