import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ParametrosService {
  apiURL: string = environment.apiURLBase;

  constructor( private http: HttpClient ) { }

  getAllTipoDeDados(): Observable<[]>{
    return this.http.get<[]>(this.apiURL + '/api/servicos/tipo/dados');
  }

  getAllTipoDeServico(): Observable<[]>{
    return this.http.get<[]>(this.apiURL + '/api/servicos/tipo/servicos');
  }
}
