import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserRoleInterface } from './user-role.interface';


@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  apiURL: string = environment.apiURLBase + '/api/roles';

  constructor( private http: HttpClient ) { }

  getUserRoles(): Observable<UserRoleInterface[]>{
    return this.http.get<UserRoleInterface[]>(this.apiURL);
  }
}
