import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingLayoutComponent } from 'src/app/layout/messaging-layout/messaging-layout.component';
import { AuthGuard } from '../auth/auth.guard';
import { QueueListComponent } from './queue-list/queue-list.component';

const routes: Routes = [
  { path: 'queue', component: MessagingLayoutComponent, canActivate: [AuthGuard], children: [
    { path: 'list', component: QueueListComponent },
    { path: '', redirectTo: '/queue/list', pathMatch: 'full' }
  ], data: { role: [] } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QueueRoutingModule { }
