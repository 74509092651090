<div class="container-fluid">
    <div class="alert alert-danger" *ngIf="queueListError">
        {{ errors }}
    </div>
    <div class="card-body">
        <form [formGroup]="queueForm" (keydown.enter)="$event.preventDefault()">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary">
                        <button mat-raised-button (click)="reeenviaMensagem()" 
                            class="btn btn-warning float-right button-reenviar"
                            matTooltip="Reprocessa o reenvio das mensagens selecionadas!"
                            [disabled]="tipo == 'EMPROC' || tipo == 'SUCESSO'">
                            <em class="material-icons">autorenew</em>                            
                        </button>
                        <button (click)="aplicarFiltroProtocolo()" mat-raised-button
                            class="btn btn-warning float-right">
                            <em class="material-icons">filter_alt</em>
                            Filtrar
                        </button>
                        <input id="queue_search" matInput formControlName="queueInput"
                        placeholder="Informe o número do protocolo" class="form-control float-right"
                        style="width: 40%; margin-right: 5px;">
                        <h4 class="card-title">Fila de processamento</h4>
                        <a routerLink="/dashboard">
                            <span class="material-icons">
                                assignment_return
                            </span>
                        </a>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" aria-hidden="true">
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                  <mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                                  [checked]="selection.hasValue() && isAllSelected()"
                                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                  [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                                  (change)="$event ? selection.toggle(row) : null"
                                                  [checked]="selection.isSelected(row)"
                                                  [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                  </mat-cell>
                                </ng-container>
                              
                                <!-- Protocolo Column -->
                                <ng-container matColumnDef="protocolo">
                                    <mat-header-cell *matHeaderCellDef> 
                                        <div mat-sort-header>Protocolo</div>
                                            <input matInput
                                            class="form-control filter-input" 
                                            formControlName="protocolInput"
                                            (keyup) = aplicarFiltroTabela()
                                            placeholder="Filtrar por Protocolo">
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.protocolo}} </mat-cell>
                                </ng-container>

                                <!-- Data do processamento Column -->
                                <ng-container matColumnDef="dataInicioProcessamento">
                                    <mat-header-cell *matHeaderCellDef>
                                        <div mat-sort-header>Data do Processamento</div> 
                                        <input matInput
                                        class="form-control filter-input" 
                                        formControlName="dateInput"
                                        (keyup) = aplicarFiltroTabela()
                                        placeholder="Filtrar por Data">
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> 
                                        {{ element.dataInicioProcessamento == null ? 'Não processado' 
                                        : element.dataInicioProcessamento | date: 'dd/MM/yyyy HH:mm'}} </mat-cell>
                                </ng-container>

                                <!-- Status Column -->
                                <ng-container matColumnDef="statusProcessamento">
                                    <mat-header-cell *matHeaderCellDef>
                                        <div mat-sort-header>Status</div> 
                                        <input matInput 
                                        formControlName="statusInput"
                                        class="form-control filter-input" 
                                        (keyup) = aplicarFiltroTabela()
                                        placeholder="Filtrar por Status">
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.statusProcessamento}} </mat-cell>
                                </ng-container>
                              
                                <!-- Mensagem Column -->
                                <ng-container matColumnDef="mensagemErro">
                                    <mat-header-cell *matHeaderCellDef> 
                                        <div mat-sort-header>Mensagem </div>
                                        <input matInput 
                                        formControlName="mensagemInput"
                                        class="form-control filter-input" 
                                        (keyup) = aplicarFiltroTabela()
                                        placeholder="Filtrar por Mensagem">
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.mensagemErro}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"
                                    (click)="selection.toggle(row)">
                                </mat-row>

                            </mat-table>
                            
                            <mat-paginator
                            #paginator
                            [pageSize]="pageSize" 
                            [pageSizeOptions]="[2,5,10, 25, 50, 100]"
                            [length]="totalLength"
                            (page)="onChangePage($event)"
                            [showFirstLastButtons]="true"
                            >
                            </mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>  