import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './messaging/dashboard/dashboard-page/dashboard.component';
import { MessagingLayoutComponent } from './layout/messaging-layout/messaging-layout.component';
import { SidebarComponent } from './layout/template/sidebar/sidebar.component';
import { NavbarComponent } from './layout/template/navbar/navbar.component';
import { FooterComponent } from './layout/template/footer/footer.component';
import { LoginComponent } from './messaging/login/login-form/login.component';
import { AuthService } from './messaging/auth/auth.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './messaging/auth/token.interceptor';
import { SystemService } from './messaging/systems/system.service';
import { UserService } from './messaging/users/user.service';
import { SystemsModule } from './messaging/systems/systems.module';
import { UsersRoutingModule } from './messaging/users/users-routing.module';
import { UsersModule } from './messaging/users/users.module';
import { SharedModule } from './messaging/shared/shared.module';
import { LoginModule } from './messaging/login/login.module';
import { DashboardModule } from './messaging/dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import {MatDialogModule} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ProtocolsModule } from './messaging/protocols/protocols.module';
import { ProtocolsRoutingModule } from './messaging/protocols/protocols-routing.module';
import { QueueModule } from './messaging/queue/queue.module';
import { QueueRoutingModule } from './messaging/queue/queue-routing.module';
import { PasswordDialog } from './layout/template/password-change/password-change';
import { HttpResponseInterceptor } from './messaging/shared/show-http-response/http-response-interceptor.interceptor';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoaderInterceptor } from './messaging/shared/loader/loader.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './messaging/shared/loader/loader.component';
import { LoaderService } from './messaging/shared/loader/loader.service';
import { NotAuthorizedComponent } from './layout/template/not-authorized/not-authorized.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoaderComponent,
    MessagingLayoutComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    PasswordDialog,
    NotAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SystemsModule,
    FormsModule,
    ReactiveFormsModule,
    UsersModule,
    UsersRoutingModule,
    SharedModule,
    LoginModule,
    DashboardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    NgxPermissionsModule.forRoot(),
    ProtocolsModule,
    ProtocolsRoutingModule,
    QueueModule,
    QueueRoutingModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule
  ],
  exports: [
    NgxPermissionsModule
  ],
  providers: [
    SystemService,
    UserService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
