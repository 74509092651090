export let email: Object = {
  id: null,
  processamentoEmail: {
    id: null,
    destinatarios: [],
    assunto: "Teste de email do mensageria",
    tipoMensagemEmail: "HTML",
    mensagem: "PGh0bWw+DQo8Ym9keT4NCjxoMT5UZXN0ZSBkZSBlbWFpbDwvaDE+DQo8ZGl2PiBFc3RlIMOpIHVtIHRlc3RlIGRlIGVudmlvIGRlIGVtYWlsIHBlbG8gbWVuc2FnZXJpYTwvZGl2Pg0KPC9ib2R5Pg0KPC9odG1sPg==",
    copiaOculta: "0",
    anexosEmail: null,
  },
  protocolo: "",
  dataAgendamento: null,
  tipoDeServico: "EMAIL",
  dataInicioProcessamento: null,
  dataFimProcessamento: null,
  usuario: null
}
