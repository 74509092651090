import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueueInterface } from './queue.interface';
import { UserInterface } from '../users/user.interface';
import { DashboardInterface } from '../dashboard/dashboard.interface';

@Injectable({
providedIn: 'root'
})
export class QueueService {
apiURL: string = environment.apiURLBase + '/api/batch';

constructor( private http: HttpClient ) { }

  getAllQueues(): Observable<QueueInterface[]>{
    return this.http.get<QueueInterface[]>(this.apiURL);
  }

  reenviarMensagem(lista: DashboardInterface[]): Observable<any>{
    return this.http.post<DashboardInterface>(this.apiURL + '/reenviar-mensagem',lista);
  }

}