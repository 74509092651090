import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { param } from 'jquery';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../users/user.interface';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiURL: string = environment.apiURLBase + '/api/usuarios';
  private tokenURL: string = environment.apiURLBase + environment.obterTokenUrl;
  private clientID: string = environment.clientId;
  private clientSecret: string = environment.clientSecret;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private userRole: string;

  constructor(
    private http: HttpClient
  ) { }

  getCurrentUserRole() {
    return JSON.parse(localStorage.getItem('ROLE') || '{}');
  }


  clearSession(): any {
    localStorage.removeItem('access_token');
    localStorage.removeItem('ROLE');
    localStorage.removeItem('PERMISSIONS');
    localStorage.removeItem('id_sistema');
  }

  getUserAuthenticated(): null {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token).user_nameuser;
    }
    return null;
  }

  getToken(): null {
    const tokenString = localStorage.getItem('access_token');
    if (tokenString) {
      const token = JSON.parse(tokenString).access_token;
      return token;
    }
    return null;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      const expired = this.jwtHelper.isTokenExpired(token);
      return !expired;
    }
    return false;
  }

  save(user: UserInterface): Observable<any> {
    return this.http.post<any>(this.apiURL, user);
  }

  authenticate(username: string, password: string): Observable<any> {
    const params = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    const headers = {
      'Authorization': 'Basic ' + btoa(`${this.clientID}:${this.clientSecret}`),
      'Content-type': 'application/x-www-form-urlencoded'
    };
    return this.http.post(this.tokenURL, params.toString(), { headers });
  }

  setLocalStorageToken(token: ObjectConstructor): void {
    localStorage.setItem('access_token', JSON.stringify(token));
  }

  setLocalStoragePermissions(permissions: any): void {
    //console.log('Permissions: ' + permissions);
    localStorage.setItem('ROLE', permissions.userRole.nome);

    localStorage.setItem('id_sistema', permissions.sistemaExterno.id);

    permissions.privilegios.push({ nome: permissions.userRole.nome });
    localStorage.setItem('PERMISSIONS', JSON.stringify(permissions.privilegios));
  }

  getAuthPermissions(username: string): Observable<any> {
    return this.http.get(environment.apiURLBase + '/api/sistemas/user/' + username);
  }

  getLoggedUserPermissions(): Observable<any> {
    return this.http.get(this.apiURL + '/privilegios');
  }

  getSessionUserRole(): Observable<string> {
    if (this.userRole) {
      return of(this.userRole);
    } else {
      return this.http.get<{ role: string }>(`${this.apiURL}/role`).pipe(
        map(response => {
          this.userRole = response.role;
          return response.role;
        }),
        catchError(() => of(null))
      );
    }
  }


}
