import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../users/user.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;
  
  public formulario: FormGroup;
  public username: string;
  public password: string;

  public errors: Array<string> = [];
  public loginError = false;
  public loginSuccess = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formulario = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  onSubmit(): void {
    if(this.formulario.get('username').value =='' 
      || this.formulario.get('username').value == undefined 
      || this.formulario.get('password').value == '' 
      || this.formulario.get('password').value == undefined){
        this.loginError = true;
        this.errors = ['Favor informar o login e a senha!'];
    }else{
      this.authService.authenticate(this.formulario.get('username').value, 
      this.formulario.get('password').value).subscribe({
        next: (token) => {
          this.authService.setLocalStorageToken(token);
          if (token) {
            this.authService.getAuthPermissions(this.formulario.get('username').value).subscribe({
              next: (permissions) => {
                this.authService.setLocalStoragePermissions(permissions);
                if (permissions.userRole.nome === 'ROLE_MASTER') {
                  this.router.navigate(['/systems/list']);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }
            });
          }
        },
        error: error => {
          this.loginError = true;
          this.errors = ['Usuário / Senha Incorretos!'];
          //console.log(error);
        }
      });
    }
  }
  onResetSenha() : void{
    console.log('Resete senha...' + this.formulario.get('username').value);
    this.userService.resetPasswordAdminMaster().subscribe({
      next: () => {
        this.loginSuccess = true;
        this.loginError = false;
        this.errors = ['Senha reiniciada com sucesso!'];
      },
      error: error => {
        this.loginError = true;
        this.errors = ['Falha no reset da senha do admin_master!'];
      }
    });
  }
}
