import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserFormComponent } from './user-form/user-form.component';
import { UsersRoutingModule } from './users-routing.module';
import { UsersSearchPipe } from './user-list/user-search.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import { NgxPermissionsModule } from 'ngx-permissions';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ControlRequiredPipe } from './user-form/user-form-required.pipe';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [UserListComponent, UserFormComponent, UsersSearchPipe, ControlRequiredPipe],
  imports: [
    CommonModule,
    FormsModule,
    UsersRoutingModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    SharedModule,
    MatTabsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatCardModule,
    NgxPermissionsModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    ClipboardModule,
    MatIconModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersModule { }
