import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserInterface } from '../user.interface';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public users: UserInterface[];
  public systemsUsers: [];
  public userListForm: FormGroup;
  public userSelecionado: UserInterface;
  public errors: Array<string> = [];
  public errorUser = false;
  
  public showUsers = false;

  constructor(
    private service: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog
  ) { 
    this.titleService.setTitle('Usuários');
  }

  ngOnInit(): void {
    this.carregaUsuarios();
  }
  carregaUsuarios() : void {
    this.service.getUsers().subscribe(res => this.systemsUsers = this.groupForSystems(res));
    
    this.userListForm = this.formBuilder.group({
      filter: ['', Validators.required]
    })
  }
  groupForSystems(users): any {
    return users.reduce((result: object, user: any) => {
      let id = user.sistemaExterno.id;

      !result[id] ? result[id] = {
        nomeSistema: user.sistemaExterno.nomeSistema,
        users: [user]
      } : result[id].users.push(user);

      return result;
    }, {});
  }

  showUsersSystem(system) {
    this.users = system.value.users;
    this.showUsers = true;
  }

  filterUser () {
    console.log('Filtro: ' + this.userListForm.get('filter').value);
    this.users.find( x => x.nomeUsuario.startsWith(this.userListForm.get('filter').value));
  }

  confirmAction(userSelected: UserInterface) {

    const dialogData = new ConfirmDialogModel("Remover Usuário", "Deseja remover o usuário selecionado?");
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.preparaDelecao(userSelected)
        }
    })

  }

  preparaDelecao(user: UserInterface){
    this.userSelecionado = user;
    this.service.delete(user).subscribe({
      next: () => {        
        this.errorUser = false;        
        this.reloadCurrentRoute();
      },
      error: error => {
        console.log('erro');
        this.errorUser = true;
        this.errors = [error.error.errors];
      }
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }
}
