import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css']
})
export class NotAuthorizedComponent{

  constructor(private router: Router) { }

  goToDashboard(){
    this.router.navigate(['dashboard']);
  }

}
