import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SendEmailTestInterface } from './send-email-test.interface';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  apiURL: string = environment.apiURLBase + '/api/entradaemail/test';

  constructor( private http: HttpClient ) { }

  sendEmailTest(email: Object): Observable<SendEmailTestInterface[]>{
    return this.http.post<SendEmailTestInterface[]>(this.apiURL, email);
  }
}
