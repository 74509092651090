<div class="container">
  <div class="row justify-content-center">

  <h1 mat-dialog-title>
    {{title}}
  </h1>

  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onAccept()">Sim</button>
    <button mat-raised-button color="warn" (click)="onReject()">Não</button>
  </div>

  </div>
</div>