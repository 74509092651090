<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand text-uppercase" href="javascript:;">{{getTitle()}}</a>
    </div>
    <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">person</i>
            <p class="d-lg-none d-md-block">
              Account
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
            <a class="dropdown-item" mat-button (click)="openDialog()"><i class="material-icons">autorenew</i> &nbsp; Alterar a senha</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()" href="#"><i class="material-icons red">logout</i> &nbsp; Sair</a>
          </div>
        </li>
      </ul>
    </div>    
  </div>
</nav>
<!-- End Navbar -->
