<div class="wrapper">
  <app-sidebar></app-sidebar>
  <div class="main-panel">
    <app-navbar></app-navbar>
    <div class="content">
      <app-loader></app-loader>
      <router-outlet>
      </router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>



