import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagingLayoutComponent } from 'src/app/layout/messaging-layout/messaging-layout.component';
import { AuthGuard } from '../auth/auth.guard';
import { DashboardComponent } from './dashboard-page/dashboard.component';

const routes: Routes = [
  { path: 'dashboard', component: MessagingLayoutComponent, children: [
    { path: '', component: DashboardComponent }
  ], canActivate : [AuthGuard], data: { role: ['ROLE_MODERADOR', 'ROLE_SUPORTE'] } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
