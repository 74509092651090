<div class="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">

  <div class="logo">
      <span class="simple-text logo-normal">SISTEMA MENSAGERIA</span>
  </div>
  <div class="sidebar-wrapper">
    <ul class="nav">

      <li [routerLinkActive]="['active']" *ngFor="let item of items" class="{{item.class}} nav-item">
        <ng-template ngxPermissionsOnly="{{item.role}}">
          <a class="nav-link" [routerLink]="[item.path]">
              <i class="material-icons">{{item.icon}}</i>
              <p>{{item.title}}</p>
          </a>
        </ng-template>
      </li>
    </ul> 
  </div>
</div>
